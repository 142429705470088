$theme-color: #33cc99;
$theme-color-dark: darken($theme-color, 5%);
$theme-color-light: lighten($theme-color, 10%);

$theme-secondary: #00d9cd;

$light-grey: #f3f3f3;
$grey: #eaeaea;
$background-grey: #fdfeff;

$box-shadow-1: 0px 12px 19px rgba(#33cc99, 0.09);
$box-shadow-2: 0px 10px 25px rgba(#b7bbd6, 0.5);
$theme-shadow: 0px 10px 25px rgba($theme-color, 0.3);
$secondary-shadow: 0px 10px 19px rgba($theme-secondary, 0.2);

$text-color: #344356;
$text-color-strong: #344356;
$text-color-light: #344356;

$button-background: #efefef;
