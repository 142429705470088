@import "styles/variables";
.cta {
  $padding: 5px;
  transition: 0.5s all;
  display: flex;
  background-color: $theme-color;
  border-radius: 20px;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: $theme-shadow;
  cursor: pointer;

  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;

  &.round {
    height: 40px;
    width: 40px;
  }

  &[data-primary="false"] {
    background-color: #fff;
    border: 1px solid $grey;
    color: #535353;
    box-shadow: $box-shadow-1;
  }

  &[data-large="true"] {
    height: 45px;
    border-radius: 25px;
    min-width: 150px;
  }

  .label {
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: 0.8em;

    @media (max-width: 330px) {
      font-size: 0.75em;
    }
  }

  .icon {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1em;
  }

  &.disabled {
    cursor: not-allowed !important;
  }
}
