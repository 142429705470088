.menu-container {
  position: fixed;
  left: 0;
  right: 0;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-container {
    .logo {
      max-height: 4em;
    }
  }
}
