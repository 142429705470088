@import "variables";
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,700,800&display=swap");

body {
  margin: 0;
  font-family: "Poppins", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-grey;
}

.touch-scroll {
  -webkit-overflow-scrolling: touch;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

.drawer-container {
  z-index: 1000 !important;
}
