@import "styles/variables";

.feedback-help-popup-content {
  max-width: 700px;
  width: 80% !important;
  border-radius: 8px;

  .modal {
    .title {
      padding: 10px;
      font-weight: 600;
      border-bottom: 1px solid $grey;
    }
    .help-modal-content {
      min-height: 300px;
      text-align: unset;
      padding: 10px;

      p {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .modal-close {
      text-align: center;
      padding: 20px;
      .link-like {
        cursor: pointer;
        color: $theme-color;
        font-size: 1em;
        text-decoration: none;
      }
    }
  }
}
