@import "styles/variables";

.step-container {
  display: flex;
  justify-content: center;
}

.action-container {
  margin-top: 90px;
  width: 100%;
  max-width: 700px;
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 13px;
  box-shadow: $box-shadow-1;
  position: relative;
  color: $text-color;

  $padding: 40px;
  margin-bottom: 80px;

  padding: $padding;

  .discover-pickme {
    padding-top: 50px;
    font-size: 0.8em;
  }

  @media (max-width: 700px) {
    // flex-grow: 1;
    display: block;
    padding: 40px 20px;
  }

  .message {
    font-size: 1em;
    line-height: 1.35em;
    color: $text-color;
    margin: 0 auto;

    .title {
      text-align: center;
    }

    .content {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 0.8em;
      text-align: center;
      line-height: 1.5em;
    }

    p {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .subquestion {
      padding-bottom: 0;
    }

    .message-gray {
      color: #b4b4b4;
      font-size: 0.7em;
      padding-top: 0;
      font-style: italic;
    }
  }

  .title {
    font-size: 1.2em;
  }

  .feedback-information {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      text-align: center;
    }

    .feedback-information-details {
      background-color: #fafafa;
      border-radius: 8px;
      padding: 20px;
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }

  .feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .feedback-editor {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 40px;

      @media (max-width: 700px) {
        margin-bottom: 30px;
      }

      .input[data-behavior="textarea"] {
        height: 120px;
        font-size: 1.1em;
        border-radius: 0;

        textarea {
          resize: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border: 0;
          border-bottom: solid 0.5px $theme-color;
          padding: 1.2em;

          &:focus {
            border-bottom: solid 2px $theme-color;
          }
        }
      }

      .hint {
        padding-top: 5px;
        display: flex;
        font-size: 0.9em;
        color: #9a9a9a;
        justify-content: space-between;
      }
    }
  }

  .tips {
    padding-top: 20px;

    text-align: center;
    width: 70%;

    .link-like {
      text-decoration: underline;
      color: rgb(130, 130, 133);
      cursor: pointer;
    }
  }

  &.validation {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cta-bloc {
    margin-top: 30px;
    &.bottom {
      bottom: $padding;
      left: $padding;
      right: $padding;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 700px) {
      margin-top: 15px;
    }

    .cta-container {
      min-width: 300px;

      @media (max-width: 350px) {
        min-width: 250px;
      }
    }
  }
}

.action-container-width {
  max-width: 787px;
  padding: 40px 0;
}

.help-button-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.peer-rating {
  text-align: center;

  .skill {
    font-weight: 700;
  }

  .rating-container {
    padding-top: 30px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 700px) {
      padding-top: 20px;
      margin-bottom: 15px;
    }

    .rating-values {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2em;
      transition: font-size 1s;

      .rating-item {
        display: flex;
        cursor: pointer;
        margin: auto 0.25em;
        width: 2.5em;
        height: 2.5em;
        font-size: 0.6em;
        align-items: center;
        justify-content: center;
        border-radius: 0.3em;

        @media (max-width: 700px) {
          margin: 0.5em 0.2em;
        }
      }

      .rating-item-text {
        display: flex;
        cursor: pointer;
        margin: auto 0.25em;
        height: 2.5em;
        font-size: 0.45em;
        line-height: 1em;
        align-items: center;
        justify-content: center;
        border-radius: 0.3em;
        padding: 0 1em;

        @media (max-width: 700px) {
          margin: 0.5em 0.2em;
        }
      }

      @media (max-width: 700px) {
        flex-flow: row wrap;
      }
    }

    .rating-content {
      margin-top: 2em;
      height: 4em;

      .rating-emoji {
        font-size: 2.5em;
        line-height: 1.2em;
      }
      .rating-text {
        font-size: 1em;
      }
    }

    .question-container {
      margin-bottom: 1.5em;

      @media (max-width: 700px) {
        margin-bottom: 1em;
      }

      .question-title {
        font-size: 0.94em;
        margin-bottom: 0.8em;

        @media (max-width: 700px) {
          margin-bottom: 0.1em;
        }
      }
    }
  }

  .question-container-v2 {
    margin-bottom: 2em;

    @media (max-width: 700px) {
      margin-bottom: 1.5em;
    }

    .question-container-v2-spacing {
      margin-top: 2.5em;

      @media (max-width: 700px) {
        margin-top: 1.5em;
      }
    }

    .question-values {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      transition: font-size 1s;
      margin-top: 0.35em;

      &:has(> :nth-child(3)) {
        flex-direction: column;
      }

      .question-item-text {
        display: flex;
        cursor: pointer;
        height: 2.5em;
        font-size: 0.45em;
        line-height: 1em;
        align-items: center;
        justify-content: center;
        border-radius: 0.3em;
        padding: 0 0.5em;
        margin: auto 0.25em 0.35em;

        //&:last-of-type {
        //  margin-bottom: 0;
        //}

        @media (max-width: 480px) {
          margin: 0.5em 0.2em;
        }
      }
    }
  }
}

.peer-feedback-loader {
  padding-top: 100px;
}

.feedback-message-help-popup-content {
  max-width: 340px;
  width: 340px !important;
  border-radius: 8px;

  .modal {
    .title {
      padding: 10px;
      font-weight: 600;
      border-bottom: 1px solid $grey;
    }
    .feedback-tips {
      .tip {
        text-align: start;
        padding: 20px;
        padding-left: 30px;
        padding-right: 30px;
        .icon {
          padding-right: 10px;
          font-size: 1.4em;
        }

        display: flex;
      }
    }

    .modal-close {
      padding: 20px;
      .link-like {
        cursor: pointer;
        color: $theme-color;
        font-size: 1.1em;
        text-decoration: none;
      }
    }
  }
}
